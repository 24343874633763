import React from "react";
import { Container } from "react-bootstrap";

export default function Details(props) {
  let hasHighlightText = props.highlightText !== undefined;
  let hasBoldText = props.boldText !== undefined;
  return (
    <>
      <Container fluid>
        <div className="details text-justify fs-6 mb-3 mx-4">
          {
            hasHighlightText && <span className="fs-5 fw-bold highlightText">{props.highlightText}</span>
          }
          {
            hasBoldText && <span className="fs-6 fw-bold">{props.boldText}</span>
          }
           {props.normalText}
        </div>
      </Container>
    </>
  );
}
