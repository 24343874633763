import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from "./pages/_Layout";
import VisitUsPage from './pages/VisitUsPage';
import NoPage from "./pages/NoPage";
import DonationPage from "./pages/DonationPage";
import TeamPage from "./pages/TeamPage";
import AboutPage from "./pages/AboutPage";
import ActivityPage from "./pages/ActivityPage";
import TSAEvent from "./pages/events/TSAEvent";
import EvDislexieEvent from "./pages/events/EvDislexieEvent";
import Anxiety from "./pages/articles/Anxiety";
import Dyslalia from "./pages/articles/Dyslalia";
import CognitiveImpairement from "./pages/articles/CognitiveImpairement";
import SpeechTherapy from "./pages/articles/SpeeechTherapy";
import AutismDayEvent from "./pages/events/AutismDayEvent";
import WorkshopEvent from "./pages/events/WorkshopEvent";
import TherapeuticCamp from "./pages/events/TherapeuticCamp";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<AboutPage />} />
          <Route path="/activity" element={<ActivityPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/visitus" element={<VisitUsPage />} />
          <Route path="/donation" element={<DonationPage />} />
          <Route path="/events/therapeuticcamp" element={<TherapeuticCamp />} />
          <Route path="/events/workshopevent" element={<WorkshopEvent />} />
          <Route path="/events/autismdayevent" element={<AutismDayEvent />} />
          <Route path="/events/tsaevent" element={<TSAEvent />} />
          <Route path="/events/evdislexieevent" element={<EvDislexieEvent />} />
          <Route path="/articles/anxietyarticle" element={<Anxiety />} />
          <Route path="/articles/dyslaliaarticle" element={<Dyslalia />} />
          <Route path="/articles/cognitiveimpairementarticle" element={<CognitiveImpairement />} />
          <Route path="/articles/speechtherapyarticle" element={<SpeechTherapy />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

