import React from "react";
import Objective from "./ObjectiveComponent";
import Details from "./DetailsComponent";

export default function Objectives(props) {
  return (
    <>
      <Details highlightText={props.highlightTitle} normalText={props.normalTitle} />
        {props.objectives.map((objText,i)=> {
            return (<Objective key={i} objective={objText} talign={props.talign} />)
        })}
    </>
  );
}
