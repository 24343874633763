import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Details from "../../components/DetailsComponent";
import EventWImage from "../../components/EventWImageComponent";
import { Image } from "react-bootstrap";

export default function TherapeuticCamp() {

  const intrebari = [
    "Cream oportunitati de desensibilizare a copiilor cu autism care sa permita ulterior frecventarea de catre acestia si familiilor lor a mai multor medii.",
    "Facilitam generalizarea achizitilor dobandite in terapia curenta.",
    "Contribuim la dezvoltarea unei retele de suport relational si social intre parinti, la crearea unei retele de intrajutorare intre familiile participante.",
  ];

  const text1 = "Unul din proiectele pe care dorim sa le implementam anul acesta a luat nastere din dorinta de a oferi mai mult: mai mult sprijin, intelegere si suport, atat copiilor cu autism cat si familiilor acestora. Primirea diagnosticului presupune schimbari semnificative in viata familiei copilului cu TSA iar procesul de recuperare presupune participarea la diverse tipuri de terapii, costuri financiare ridicate, limitarea vietii sociale.";

  const text2 = "Avand in vedere dificultatile asociate tulburarii de spectru autist (dificultati de comunicare si relationare, dificultati de adaptare la spatii si contexte noi, aderenta la rutina, probleme de procesare senzoriala, interese restrictive), precum si limitarea mediilor sociale frecventate de familiile acestora, organizarea taberei terapeutice va crea oportunitati noi de generalizare a cunostintelor invatate, precum si un mediu facilitator desensibilizarii senzoriale.";

  const text3 = "In timpul taberei, oferim in final 70 de sedinte de terapie specializata pentru 12 copii diagnosticati cu autism si parintii lor (pe langa asigurarea cazarii si transportului), prin care:";

  const text4 = "Daca doriti sa ne sprijiniti si sa ne sustineti in implementarea acestui proiect, o puteti face printr-o donatie in contul asociatiei sau prin redirectionarea formularului 230.";

  const text5 = "Daca doriti sa ne cunoasteti si sa aflati mai multe despre activitatile pe care le desfasuram, va rugam sa nu ezitati sa ne scrieti sau chiar sa ne vizitati la sediul nostru.";

  return (
    <>

    <Helmet>
        <title>Tabara terapeutica pentru copii cu autism</title>
        <meta
          name="description"
          content="Workshop dedicat specialistilor din domeniu care vor sa isi aprofundeze cunostintele cu privire la terapia de integrare senzoriala, dar si parintilor si cadrelor didactice care vor sa inteleaga ce se afla dincolo de toate aceste comportamente. "
        />
        <link rel="canonical" href="https://www.psinapsis.ro/events/therapeuticcamp" />
      </Helmet>

    <Row className="p-0 m-0">
      <Col className="d-none d-xl-block"></Col>
      <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">

        <Title title='"Eu nu stiu cum se aude marea" - Tabara terapeutica pentru copii cu autism' />

        <Details normalText= {text1} />
        <Details normalText= {text2} />

        <Image
            className="photoEvent d-block d-md-none mx-auto mb-4"
            src="/images/tabara terapeutica 3.jpg"
            alt="Evaluarea dislexiei si disortografiei, Asociatia PsiNapsis, Sibiu"
          />

        <Details normalText="Activitatile propuse in cadrul taberei:" />
        <Details
            boldText="Terapie de desensibilizare senzoriala: "
            normalText="In functie de evaluarea psihologica efectuata, fiecare copil va primi interventie personalizata, in vederea imbunatatirii raspunsului senzorial. Astfel, vom organiza sesiuni de terapie senzoriala in functie de hipo/ hipersensibilitatea copiilor."
        />
        <Details
            boldText="Terapie individuala pentru generalizarea abilitatilor: "
            normalText="Terapeutii vor sustine sedinte individuale dupa un program prestabilit, dupa caz, in medii naturale pentru generalizarea abilitatilor dobandite in decursul terapiei individuale."
        />
        <Details
            boldText="Terapie de grup copii – socializare: "
            normalText="Activitatile desfasurate vor viza dezvoltarea unor abilitati de comunicare si relationare eficiente prin  jocuri interactive,  astfel incat copiii sa invete sa comunice intre ei si sa exerseze diverse abilitati sociale."
        />

        <Details
            boldText="Grup de suport pentru parinti: "
            normalText="In cadrul taberei, vom crea grupuri de sprijin pentru parinti (discutii deschise despre managementul crizelor comportamentale, sprijin emotional si incurajare, informatii practice despre gestionarea provocarilor zilnice ce apar in contextul psihodiagnosticului)."
        />

        <Details boldText={text3} />
        
        <EventWImage
            photoSource="/images/tabara terapeutica 3.jpg"
            alt="Evaluarea dislexiei si disortografiei, Asociatia PsiNapsis, Sibiu"
            objectives={intrebari}
            normalText={text4}
          />

          <div className="mx-5 mb-4 mt-3 text-center">
            <Button
              className="downloadBtn"
              href="/files/FORMULAR 2024.pdf"
              target="_blank"
              download
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-cloud-arrow-down-fill downloadIcon"
                viewBox="0 0 16 16"
              >
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
              </svg>
              Descarca formularul 230
            </Button>
          </div>

        <Details normalText={text5} />

      </Col>
      <Col className="d-none d-xl-block"></Col>
    </Row>
    </>
  );
}
